import React, {useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import Page from '../../site/layouts/Page';
import ArticleHeader from './ArticleHeader';
import ArticleContent from './ArticleContent';
import ArticleFeature from './ArticleFeature';
import ArticleFooter from './ArticleFooter';
import SEO from '../SEO/SEO';
import ArticleCategoryGql from '../../../gql/frontend/ArticleCategoryGql';
import ArticleSchemaOrg from '../SEO/ArticleSchemaOrg';

const SingleArticleTemplate = ({
  id,
  slug,
  categoryId,
  category,
  title,
  featuredAttachment,
  content,
  excerpt,
  publishedAt,
  updatedAt,
  tags = [],
  showSidebar = false,
}) => {
  const [getCategory, {loading, data}] = useLazyQuery(
    ArticleCategoryGql.queries.get,
  );

  useEffect(() => {
    if (!category && categoryId) {
      // Admin experience doesn't load relationships so load in that case
      (async () => {
        getCategory({
          variables: {id: categoryId},
        });
      })();
    }
  }, [category, categoryId, getCategory]);

  if (loading) {
    return null;
  }

  const loadedCategory = data ? data.getArticleCategory : {};
  const {title: categoryTitle, slug: categorySlug} = category || loadedCategory;
  const articleLink = `/articles/${slug}`;
  const seoTags = Array.isArray(tags)
    ? tags
        .map(t => {
          if (typeof t === 'string') return t;
          return t.tag;
        })
        .filter(t => typeof t === 'string' && t.length > 0)
    : [];

  return (
    <Page sidebar={showSidebar}>
      <SEO
        title={title}
        description={excerpt}
        pathname={articleLink}
        keywords={seoTags}>
        <ArticleSchemaOrg
          title={title}
          pathname={articleLink}
          excerpt={excerpt}
          publishedAt={publishedAt}
          updatedAt={updatedAt}
          attachment={featuredAttachment}
        />
      </SEO>
      <article>
        <ArticleHeader
          title={title}
          publishedAt={publishedAt}
          articleLink={articleLink}
          categoryTitle={categoryTitle}
          categoryLink={`/article-categories/${categorySlug}`}
        />

        <ArticleFeature
          attachment={featuredAttachment}
          pageLink={articleLink}
        />

        <ArticleContent>{content}</ArticleContent>

        <ArticleFooter tags={tags} />
      </article>
    </Page>
  );
};

export default SingleArticleTemplate;
