import React, {useState} from 'react';
import styled from 'styled-components';
import get from 'lodash.get';
import {Embed, Image} from 'semantic-ui-react';
import {OutboundLink} from 'gatsby-plugin-google-analytics';
import {colors} from '../../../theme';
import {trackWatchVideo} from '../../../tracking';

const FeatureContainer = styled.div`
  width: 100%;
  margin: 0 0 20px;
`;

const CaptionContainer = styled.div`
  margin: 0.25rem 0;
  font-size: 12px;
  color: ${colors.mutedTextColor};
  text-align: center;

  a {
    font-weight: 700;
    color: ${colors.darkTextColor};

    &:hover {
      color: ${colors.brandPrimary};
      border-bottom-color: ${colors.brandPrimary};
    }
  }
`;

const Caption = ({attachment}) => {
  const {caption, contentData} = attachment;
  const {storage} = contentData;

  if (storage === 'UNSPLASH') {
    const name = get(contentData, 'user.name');
    const userLink = get(contentData, 'user.links.html');

    return (
      <CaptionContainer>
        {name && userLink && (
          <span>
            Photo by{' '}
            <OutboundLink
              href={userLink}
              target="_blank"
              rel="nofollow noopener noreferrer">
              {name}
            </OutboundLink>{' '}
            on{' '}
          </span>
        )}
        <OutboundLink
          href={
            'https://unsplash.com/?utm_source="Austin Home Properties"&utm_medium=referral'
          }
          target="_blank"
          rel="nofollow noopener noreferrer">
          Unsplash
        </OutboundLink>
      </CaptionContainer>
    );
  }

  if (caption) {
    return <CaptionContainer>{caption}</CaptionContainer>;
  }

  return null;
};

const FeatureImage = ({attachment}) => {
  const {title, altText, urls} = attachment;
  const src = urls.regular || urls.full || urls.original;
  return (
    <FeatureContainer>
      <Image src={src} title={title} alt={altText || title} fluid />
      <Caption attachment={attachment} />
    </FeatureContainer>
  );
};

const FeatureVideo = ({attachment, pageLink}) => {
  const [active, setActive] = useState(false);
  const {
    contentData: {id, service},
    previewImageUrl,
  } = attachment;
  return (
    <FeatureContainer>
      <Embed
        id={id}
        source={service}
        placeholder={previewImageUrl}
        active={active}
        onClick={() => {
          trackWatchVideo({pageLink});
          // Activate immediately vs just loading the video
          setActive(true);
        }}
      />
      <Caption attachment={attachment} />
    </FeatureContainer>
  );
};

const ArticleFeature = ({attachment, pageLink}) => {
  if (!attachment) {
    return null;
  }

  if (attachment.attachmentType === 'VIDEO') {
    return <FeatureVideo attachment={attachment} pageLink={pageLink} />;
  }

  return <FeatureImage attachment={attachment} />;
};

export default ArticleFeature;
