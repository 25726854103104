import {gql} from '@apollo/client';

const fragments = {
  entry: gql`
    fragment ArticleCategoryEntry on ArticleCategory {
      id
      title
      slug
      content
      articleCount
    }
  `,
};

const queries = {
  list: gql`
    {
      findArticleCategories {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          itemCount
          totalCount
        }
        nodes {
          ...ArticleCategoryEntry
        }
      }
    }
    ${fragments.entry}
  `,
  get: gql`
    query getArticleCategory($id: ID!) {
      getArticleCategory(id: $id) {
        ...ArticleCategoryEntry
      }
    }
    ${fragments.entry}
  `,
  create: gql`
    mutation createCategory($data: ArticleCategoryCreate!) {
      createArticleCategory(data: $data) {
        ...ArticleCategoryEntry
      }
    }
    ${fragments.entry}
  `,
  update: gql`
    mutation updateCategory($id: ID!, $data: ArticleCategoryUpdate!) {
      updateArticleCategory(id: $id, data: $data) {
        ...ArticleCategoryEntry
      }
    }
    ${fragments.entry}
  `,
  delete: gql`
    mutation deleteCategory($id: ID!) {
      deleteArticleCategory(id: $id) {
        ...ArticleCategoryEntry
      }
    }
    ${fragments.entry}
  `,
};

const getCached = cache => {
  const cacheEntry = cache.readQuery({query: queries.list});
  return {
    cacheEntry,
    categories: cacheEntry?.findArticleCategories?.nodes,
  };
};

const updateCache = (cache, record, updatedData) => {
  const {cacheEntry} = record;
  cacheEntry.findArticleCategories.nodes = updatedData;
  cacheEntry.findArticleCategories.total = updatedData.length;
  cache.writeQuery({query: queries.list, data: cacheEntry});
};

const ops = {
  updateCachePostCreate: (cache, fetchResult) => {
    const record = getCached(cache);
    const {categories} = record;
    if (categories) {
      const {
        data: {createArticleCategory: newCategory},
      } = fetchResult;
      const updatedData = [newCategory, ...categories];
      updateCache(cache, record, updatedData);
    }
  },
  updateCachePostDelete: (cache, fetchResult) => {
    const record = getCached(cache);
    const {categories} = record;
    if (categories) {
      const {
        data: {
          deleteArticleCategory: {id},
        },
      } = fetchResult;
      const updatedData = categories.filter(c => c.id !== id);
      updateCache(cache, record, updatedData);
    }
  },
};

const ArticleCategoryGql = {fragments, queries, ops};
export default ArticleCategoryGql;
