import React from 'react';
import styled from 'styled-components';
import EntryTitle from '../EntryTitle';
import EntryMeta from '../EntryMeta';

const HeaderContainer = styled.div`
  margin-bottom: 60px;
`;

const ArticleHeader = ({
  title,
  publishedAt,
  articleLink,
  categoryTitle,
  categoryLink,
}) => (
  <HeaderContainer>
    <EntryTitle as="h1">{title || '<No Title>'}</EntryTitle>
    <EntryMeta
      articleLink={articleLink}
      categoryTitle={categoryTitle}
      categoryLink={categoryLink}
      publishedAt={publishedAt}
    />
  </HeaderContainer>
);

export default ArticleHeader;
