// Adapted from: https://github.com/jlengstorf/gatsby-theme-jason-blog/blob/master/src/components/SEO/SchemaOrg.js
import React from 'react';
import {Helmet} from 'react-helmet';
import urlJoin from 'url-join';
import useSiteSettings from '../../../hooks/use-site-settings';

const schemaOrgForAttachment = ({attachment, siteSettings}) => {
  const {schemaOrgDefaultImage} = siteSettings;

  if (!attachment) {
    return schemaOrgDefaultImage;
  }

  if (attachment.attachmentType === 'VIDEO') {
    const {previewImageUrl} = attachment;
    // TODO: See if we can deduce different YouTube image url formats as required:
    // https://developers.google.com/search/docs/data-types/article#article-types
    return [previewImageUrl];
  }

  // Attachment is an image
  const {urls} = attachment;
  const url = urls.regular || urls.full || urls.original;
  return [url];
};

const ArticleSchemaOrg = ({
  title,
  pathname,
  excerpt,
  publishedAt,
  updatedAt,
  attachment,
}) => {
  const siteSettings = useSiteSettings();
  const {
    siteUrl,
    seoTitle,
    schemaOrgDefaultImage,
    schemaOrgAuthor,
    schemaOrgOrganization,
  } = siteSettings;
  const url = pathname ? urlJoin(siteUrl, pathname) : siteUrl;
  const image = schemaOrgForAttachment({attachment, siteSettings});

  const schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: seoTitle,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': urlJoin(siteUrl, 'articles'),
            name: 'Blog',
            image: schemaOrgDefaultImage,
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': url,
            name: title,
            image,
          },
        },
      ],
    },
    // https://developers.google.com/search/docs/data-types/article
    {
      '@context': 'http://schema.org',
      '@type': 'BlogPosting',
      url,
      name: title,
      alternateName: seoTitle,
      headline: title,
      image,
      description: excerpt,
      author: schemaOrgAuthor,
      publisher: schemaOrgOrganization,
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': url,
      },
      datePublished: publishedAt,
      dateModified: updatedAt,
    },
  ];

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default ArticleSchemaOrg;
