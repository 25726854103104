import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {colors} from '../../../theme';

const FooterContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
`;

const TagLinks = styled.div`
  margin: 10px 0px 40px;

  a {
    display: inline-block;
    margin-right: 5px;
    font-size: 12px;
    text-transform: none;
    color: ${colors.mutedTextColor};

    &::before {
      content: '#';
    }
    &:hover {
      color: ${colors.texasColor};
    }
  }
`;

const ShareLinks = styled.div`
  text-transform: uppercase;
`;

const TagLink = ({tag}) => {
  if (typeof tag === 'string') {
    // During admin edit, tags may be supplied in their raw string form
    return (
      <Link key={tag} to={`/article-tags/${tag}`}>
        {tag}
      </Link>
    );
  }

  const {tag: text, slug} = tag;
  return <a href={`/article-tags/${slug}`}>{text}</a>;
};

const ArticleFooter = ({tags}) => (
  <FooterContainer>
    {tags && tags.length && (
      <TagLinks>
        {tags.map(tag => (
          <TagLink key={typeof tag === 'string' ? tag : tag.tag} tag={tag} />
        ))}
      </TagLinks>
    )}

    <ShareLinks />
  </FooterContainer>
);

export default ArticleFooter;
