import React from 'react';
import styled from 'styled-components';
import {breakpoints, colors} from '../../../theme';

const Html = styled.div`
  &::after,
  &::before {
    content: '';
    display: table;
    clear: both;
  }

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  p {
    padding-top: 10px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  strong {
    font-weight: 700;
  }

  a {
    font-weight: 700;
    border-bottom: 2px solid ${colors.darkTextColor};
    color: ${colors.darkTextColor};

    &:hover {
      color: ${colors.brandPrimary};
      border-bottom-color: ${colors.brandPrimary};
    }
  }

  blockquote {
    position: relative;
    max-width: 70%;
    margin: 20px auto;
    padding: 30px 40px 20px;
    text-align: center;
    border-left: none;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: ${colors.darkTextColor};

    cite,
    small,
    .small,
    .ql-size-small {
      display: block;
      font-size: 11px;
      font-style: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: ${colors.lightTextColor};
    }
  }

  @media screen and (max-width: ${breakpoints.mobileMax}) {
    .blockquote {
      padding: 20px 0;
    }
  }
`;

const ArticleContent = ({children, html, className}) => (
  <Html
    className={className}
    dangerouslySetInnerHTML={{
      __html: children || html,
    }}
  />
);

export default ArticleContent;
