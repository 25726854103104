import React from 'react';
import {graphql} from 'gatsby';
import SingleArticleTemplate from './SingleArticleTemplate';

const SingleArticle = ({data}) => (
  <SingleArticleTemplate {...data.ahp.article} />
);

export default SingleArticle;

export const query = graphql`
  query Article($id: ID!) {
    ahp {
      article: getArticle(id: $id) {
        ...ArticleFragment
      }
    }
  }
`;
